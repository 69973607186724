<template>
  <div class="d-flex flex-column">
    <v-tabs v-model="selectedTab">
      <v-tabs-slider color="red"></v-tabs-slider>
      <v-tab v-for="tab in documentationTabs" :key="tab.id">
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab" class="mt-4">
      <v-tab-item v-for="tab in documentationTabs" :key="tab.id">
        <div class="d-flex flex-column">
          <ImagesGallery :images="documentation_files" :file-type="tab.id" :edit="true" @update="loadDocumentation" />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import ImagesGallery from "@/components/main/ImagesGallery.vue";
import StaticDataService from "@/services/StaticDataService.js";

export default {
  name: "SystemDocumentation",
  components: {
    ImagesGallery,
  },
  data() {
    return {
      documentation_files: [],
      selectedTab: 0,
    }
  },
  mounted() {
    this.loadDocumentation();
  },
  computed: {
    documentationTabs() {
      return [{
        id: 'documentation_file',
        name: this.$t('documentation_file')
      }, {
        id: 'training_file',
        name: this.$t('training_file')
      }]
    }
  },
  watch: {
    selectedTab: {
      handler() {
        this.loadDocumentation();
      }
    }
  },
  methods: {
    async loadDocumentation() {
      this.documentation_files = (await StaticDataService.index(this.documentationTabs[this.selectedTab].id)).data;

    }
  }
};
</script>
