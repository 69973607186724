import Api from "@/services/Api";

export default {
  get(table) {
    return Api().get(`static-data/${table}`);
  },
  add(table, data) {
    return Api().put(`static-data/${table}`, data);
  },
  getFiltered(table, where) {
    return Api().post(`static-data/${table}`, where);
  },
  delete(table, id) {
    return Api().delete(`static-data/${table}/${id}`);
  },
  update(table, key, data) {
    return Api().put(`static-data/${table}/${key}`, data);
  },
  index(table) {
    return Api().get(`static-data/${table}`);
  },
  indexWithParent(table) {
    return Api().get(`static-data-with-parent/${table}`);
  },
  getLocalEntities() {
    return Api().get(`local-entity/index`);
  },
  getGlobalEntities() {
    return Api().get(`global-entity/index`);
  },
};